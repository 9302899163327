var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[(
        !_vm.filterSupplier &&
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SUPPLIERS)
      )?_c('supplier-selector',{attrs:{"filterOrganization":_vm.filterOrganization},on:{"supplierChanged":(supplierId) => (_vm.selectedSupplier = supplierId)}}):_vm._e(),(
        !_vm.filterProduct && _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PRODUCTS)
      )?_c('product-selector',{attrs:{"filterOrganization":_vm.filterOrganization},on:{"productChanged":(productId) => (_vm.selectedProduct = productId)}}):_vm._e(),_c('div',[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"table-responsive align-items-center table-flush supplier-product-list",attrs:{"header-row-class-name":"thead-light","data":_vm.filteredSupplierProducts,"type":"expand"}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('taxes-selector',{attrs:{"label":_vm.$t('COMMON.TAXES'),"taxes":_vm.supplierProductsModel[props.row.id].taxes},on:{"taxesChanged":(taxes) =>
                    (_vm.supplierProductsModel[props.row.id].taxes = taxes)}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT'),"input-classes":"form-control-alternative"}},[_c('html-editor',{model:{value:(_vm.supplierProductsModel[props.row.id].excerpt),callback:function ($$v) {_vm.$set(_vm.supplierProductsModel[props.row.id], "excerpt", $$v)},expression:"supplierProductsModel[props.row.id].excerpt"}})],1)],1)]}}])}),(!_vm.filterProduct)?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PRODUCT'),"prop":"product.name","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.id < 0)?_c('base-input',[_c('product-selector',{attrs:{"filterOrganization":_vm.filterOrganization,"product":row.product.id,"allowNone":false,"showAll":false,"disabled":row.id > 0},on:{"productChanged":(productId, product) => {
                    _vm.supplierProductsModel[row.id].product.id = productId;
                    _vm.supplierProductsModel[row.id].sku = product.sku;
                    _vm.supplierProductsModel[row.id].price = product.price;
                    _vm.supplierProductsModel[row.id].taxes = product.taxes;
                  }}})],1):_vm._e(),(row.id > 0)?_c('object-link',{attrs:{"object":row.product}}):_vm._e()]}}],null,false,1791381826)}):_vm._e(),(!_vm.filterSupplier)?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.SUPPLIER'),"prop":"supplier.name","min-width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.id < 0)?_c('base-input',[_c('supplier-selector',{attrs:{"filterOrganization":_vm.filterOrganization,"supplier":row.supplier.id,"allowNone":false,"showAll":false,"disabled":row.id > 0},on:{"supplierChanged":(supplierId) => {
                    _vm.supplierProductsModel[row.id].supplier.id = supplierId;
                  }}})],1):_vm._e(),(row.id > 0)?_c('object-link',{attrs:{"object":row.supplier}}):_vm._e()]}}],null,false,3482745134)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('PRODUCTS.SKU'),"prop":"sku","min-width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('base-input',{attrs:{"placeholder":_vm.$t('PRODUCTS.SKU'),"input-classes":"form-control-alternative"},model:{value:(_vm.supplierProductsModel[row.id].sku),callback:function ($$v) {_vm.$set(_vm.supplierProductsModel[row.id], "sku", $$v)},expression:"supplierProductsModel[row.id].sku"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PRICE'),"prop":"price","min-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('base-input',{staticClass:"my-5",attrs:{"placeholder":_vm.$t('COMMON.PRICE'),"input-classes":"form-control-alternative"},model:{value:(_vm.supplierProductsModel[row.id].price),callback:function ($$v) {_vm.$set(_vm.supplierProductsModel[row.id], "price", $$v)},expression:"supplierProductsModel[row.id].price"}})]}}])}),_c('el-table-column',{attrs:{"min-width":"20px","fixed":_vm.$listActionsButtonsPosition()},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[(_vm.$currentUserCan(_vm.$permissions.PERM_EDIT_PRODUCTS))?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.DELETE'),"placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteSupplierProduct(row)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)}}])})],1)],1)]),_c('div',{staticClass:"row mt-3 add-product"},[_c('base-button',{staticClass:"btn-sm add-product-button",on:{"click":() => {
          _vm.addLine();
        }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.addLineText)+" ")]),_c('base-button',{staticClass:"btn-sm add-product-save",on:{"click":function($event){return _vm.saveSupplierProducts()}}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.SAVE"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }